import { css } from '@emotion/react'
import { IconButton } from '@fluentui/react/lib/Button'
import { IIconProps } from '@fluentui/react/lib/Icon'
import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GetResponseOptions, useChat } from '../../libs/hooks/useChat'
import { useApp, setChatLoadingState } from '../../redux/features/app/appSlice'
import { ChatLoadingStates } from '../../redux/features/app/AppState'

import {
  useConversations,
  updateBotResponseStatus
} from '../../redux/features/conversations/conversationsSlice'
import { ChatHistory } from './chat-history/ChatHistory'
import { ChatInput } from './ChatInput'
import { ChatPrompts } from './ChatPrompts'

const classes = {
  root: css({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  }),
  scroll: css({
    margin: '4px',
    height: '100%',
    overflowY: 'auto',
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        visibility: 'visible'
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#ffffff',
        WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
        visibility: 'visible'
      }
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }),
  loading: css({
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    marginTop: '15em',
    justifyContent: 'center',
    lineHeight: '1.75em',
    fontSize: '1.2em'
  }),
  history: css({
    padding: '12px',
    paddingLeft: '12px',
    paddingRight: '12px',
    display: 'flex',
    justifyContent: 'center'
  }),
  input: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '8px 0px'
  }),
  wrapper: css({
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '15px',
    display: 'flex'
  })
}

const refreshIcon: IIconProps = { iconName: 'Refresh' }

export const ChatRoom: React.FC = () => {
  const chat = useChat()
  const dispatch = useDispatch()
  const { chatLoadingState } = useApp()
  const { conversations, selectedId } = useConversations()

  const messages = useMemo(() => {
    return conversations[selectedId]?.messages || []
  }, [conversations, selectedId])

  const scrollViewTargetRef = useRef<HTMLDivElement>(null)
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true)

  useEffect(() => {
    if (!shouldAutoScroll) {
      return
    }
    scrollViewTargetRef.current?.scrollTo(
      0,
      scrollViewTargetRef.current.scrollHeight
    )
  }, [messages, shouldAutoScroll])

  useEffect(() => {
    const onScroll = () => {
      if (!scrollViewTargetRef.current) {
        return
      }
      const { scrollTop, scrollHeight, clientHeight } =
        scrollViewTargetRef.current
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10
      setShouldAutoScroll(isAtBottom)
    }
    if (!scrollViewTargetRef.current) {
      return
    }
    const currentScrollViewTarget = scrollViewTargetRef.current
    currentScrollViewTarget.addEventListener('scroll', onScroll)
    return () => {
      currentScrollViewTarget.removeEventListener('scroll', onScroll)
    }
  }, [])

  const handleSubmit = async (options: GetResponseOptions) => {
    options.advancedReasoning = conversations[selectedId].advancedReasoning
    dispatch(
      updateBotResponseStatus({
        chatId: selectedId,
        status: 'processing your request'
      })
    )
    if (options.value !== '') {
      await chat.getResponse(options)
      setShouldAutoScroll(true)
    }
  }

  const handleRefresh = () => {
    dispatch(setChatLoadingState(ChatLoadingStates.NotLoaded))
  }

  const LoadingChatMessage = () => {
    if (chatLoadingState === ChatLoadingStates.NotLoaded) {
      return (
        <p css={classes.loading}>
          Loading Chats...
          <br />
          Please Wait
        </p>
      )
    }
    if (chatLoadingState === ChatLoadingStates.ErrorLoading) {
      return (
        <div css={classes.loading}>
          <p>
            Unable to load previous chats,
            <br />
            refresh and try again.
          </p>
          <IconButton
            title="Refresh"
            aria-label="refresh load"
            iconProps={refreshIcon}
            onClick={handleRefresh}
          />
        </div>
      )
    }
    return <></>
  }

  if (conversations[selectedId]?.hidden || false) {
    return (
      <div css={classes.root}>
        <div css={classes.scroll}>
          <LoadingChatMessage />
          <div css={classes.history}>
            <h3>This conversation is not visible in the app.</h3>
          </div>
        </div>
      </div>
    )
  }

  if (conversations[selectedId] === undefined) {
    return (
      <div css={classes.root}>
        <div css={classes.scroll}>
          <LoadingChatMessage />
          <div css={classes.history} />
        </div>
      </div>
    )
  }

  return (
    <div css={classes.root}>
      <div ref={scrollViewTargetRef} css={classes.scroll}>
        <LoadingChatMessage />
        <div css={classes.history}>
          <ChatHistory messages={messages} />
        </div>
        <ChatPrompts
          onSubmit={handleSubmit}
          conversation={conversations[selectedId]}
          selectedId={selectedId}
        />
      </div>
      <div css={classes.input}>
        <ChatInput onSubmit={handleSubmit} />
      </div>
    </div>
  )
}
