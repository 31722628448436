import { css } from '@emotion/react'
import { IconButton } from '@fluentui/react/lib/Button'
import { IIconProps } from '@fluentui/react/lib/Icon'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { RouteLocation, mapLocation } from '../../../libs/models/ChatSession'
import { useConversations } from '../../../redux/features/conversations/conversationsSlice'
import { Conversations } from '../../../redux/features/conversations/ConversationsState'
import { isToday } from '../../utils/TextUtils'
import { SimplifiedNewBotMenu } from './bot-menu/SimplifiedNewBotMenu'
import { ChatListSection } from './ChatListSection'

const classes = {
  root: css({
    display: 'flex',
    flexShrink: 0,
    width: '320px',
    backgroundColor: '#f0f0f0', // colorNeutralBackground4
    flexDirection: 'column',
    overflow: 'hidden',
    '@media (max-width: 744px)': {
      width: '64px'
    }
  }),
  list: css({
    overflowY: 'auto',
    overflowX: 'hidden',
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // colorScrollbarThumb
        visibility: 'visible'
      }
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent' // colorSubtleBackground
    },
    alignItems: 'stretch'
  }),
  header: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: '12px',
    marginLeft: '20px',
    alignItems: 'center',
    height: '60px',
    '@media (max-width: 744px)': {
      justifyContent: 'center'
    }
  }),
  title: css({
    flexGrow: 1,
    '@media (max-width: 744px)': {
      display: 'none'
    },
    fontSize: '24px' // fontSizeBase500
  }),
  input: css({
    flexGrow: 1,
    padding: '0',
    border: 'none',
    backgroundColor: 'transparent', // colorSubtleBackground
    fontSize: '24px' // fontSizeBase500
  })
}

const navIcon: IIconProps = { iconName: 'GlobalNavButton' }

interface ConversationsView {
  latestConversations?: Conversations
  olderConversations?: Conversations
}

interface ChatListProps {
  toggleChatList: () => void
}

export const ChatList: FC<ChatListProps> = ({ toggleChatList }) => {
  const { selectedHouseholdId } = useRdot360Context()
  const { conversations } = useConversations()
  const location = useLocation()
  const currentLocation = mapLocation(location.pathname)

  const [conversationsView, setConversationsView] = useState<ConversationsView>(
    {
      latestConversations: {}
    }
  )

  const sortConversations = (
    conversations: Conversations
  ): ConversationsView => {
    // sort conversations by last activity
    const sortedIds = Object.keys(conversations).sort((a, b) => {
      if (conversations[a].lastUpdatedTimestamp === undefined) {
        return 1
      }
      if (conversations[b].lastUpdatedTimestamp === undefined) {
        return -1
      }
      const timestampA = new Date(conversations[a].lastUpdatedTimestamp)
      const timestampB = new Date(conversations[b].lastUpdatedTimestamp)
      return timestampB.getTime() - timestampA.getTime()
    })

    // Add conversations to sortedConversations in the order of sortedIds.
    const latestConversations: Conversations = {}
    const olderConversations: Conversations = {}
    sortedIds.forEach((id) => {
      if (isToday(new Date(conversations[id].lastUpdatedTimestamp))) {
        latestConversations[id] = conversations[id]
      } else {
        olderConversations[id] = conversations[id]
      }
    })
    return {
      latestConversations: latestConversations,
      olderConversations: olderConversations
    }
  }

  useEffect(() => {
    const nonHiddenConversations: Conversations = {}
    for (const key in conversations) {
      const conversation = conversations[key]
      if (
        !conversation.hidden &&
        conversation.location === currentLocation &&
        conversation.householdId ===
          (currentLocation === RouteLocation.ClientDashboard
            ? selectedHouseholdId
            : '')
      ) {
        nonHiddenConversations[key] = conversation
      }
    }
    setConversationsView(sortConversations(nonHiddenConversations))
  }, [conversations, currentLocation, selectedHouseholdId])

  return (
    <div css={classes.root}>
      <div css={classes.header}>
        <IconButton iconProps={navIcon} onClick={toggleChatList} />
        <SimplifiedNewBotMenu toggleChatList={toggleChatList} />
      </div>
      <div aria-label={'chat list'} css={classes.list}>
        {conversationsView.latestConversations && (
          <ChatListSection
            header="Today"
            conversations={conversationsView.latestConversations}
          />
        )}
        {conversationsView.olderConversations && (
          <ChatListSection
            header="Older"
            conversations={conversationsView.olderConversations}
          />
        )}
      </div>
    </div>
  )
}
