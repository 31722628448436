import { ChatState } from './ChatState'

export type Conversations = Record<string, ChatState>

export interface ConversationsState {
  conversations: Conversations
  selectedId: string
}

export const initialState: ConversationsState = {
  conversations: {},
  selectedId: ''
}

export interface ConversationTitleChange {
  id: string
  newTitle: string
}

export interface ConversationInputChange {
  id: string
  newInput: string
}
