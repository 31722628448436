import { Panel, PanelType } from '@fluentui/react/lib/Panel'
import { useRdot360DataFetch } from 'modules/Advisory/modules/Rdot360/shared/useRdot360DataFetch'
import { useRdot360Context } from 'modules/Advisory/modules/Rdot360/store/rdot360Context'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { LoadingComponent } from 'shared/components/Loading'
import { getAccount } from 'shared/services/auth'
import { classes } from '../../index'
import { useChat } from '../../libs/hooks/useChat'
import { RouteLocation, mapLocation } from '../../libs/models/ChatSession'
import { setActiveUserInfo, useApp } from '../../redux/features/app/appSlice'
import {
  ActiveUserInfo,
  ChatLoadingStates
} from '../../redux/features/app/AppState'
import {
  useConversations,
  setSelectedConversation
} from '../../redux/features/conversations/conversationsSlice'
import { ChatView } from './ChatView'

const Chat = ({
  showCopilot,
  setShowCopilot
}: {
  showCopilot: boolean
  setShowCopilot: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const dispatch = useDispatch()
  const chat = useChat()
  const { chatLoadingState } = useApp()
  const { conversations } = useConversations()
  const { selectedHouseholdId } = useRdot360Context()
  const activeUserLoad = useRef(false)
  const location = useLocation()
  const currentLocation = mapLocation(location.pathname)

  const skip = useRef(false)
  const prevLocation = useRef(currentLocation)
  const prevHouseholdId = useRef(
    currentLocation === RouteLocation.ClientDashboard ? selectedHouseholdId : ''
  )

  useEffect(() => {
    if (chatLoadingState === ChatLoadingStates.NotLoaded) {
      chat.loadChats()
    }
  }, [chatLoadingState, chat])

  useEffect(() => {
    // only check for an existing chat if the location or household ID (only in client dashboard) changed or if it is first trigger after chats are loaded
    if (
      chatLoadingState === ChatLoadingStates.SuccessfullyLoaded &&
      (!skip.current ||
        prevHouseholdId.current !==
          (currentLocation === RouteLocation.ClientDashboard
            ? selectedHouseholdId
            : '') ||
        prevLocation.current !== currentLocation)
    ) {
      skip.current = true
      prevHouseholdId.current =
        currentLocation === RouteLocation.ClientDashboard
          ? selectedHouseholdId
          : ''
      prevLocation.current = currentLocation

      const nonHiddenChats = Object.values(conversations).filter(
        (c) =>
          !c.hidden &&
          c.location === currentLocation &&
          c.householdId ===
            (currentLocation === RouteLocation.ClientDashboard
              ? selectedHouseholdId
              : '')
      )
      if (nonHiddenChats.length === 0) {
        dispatch(setSelectedConversation(''))
        chat.createChat(location.pathname, selectedHouseholdId ?? '')
      } else {
        dispatch(setSelectedConversation(nonHiddenChats[0].id))
      }
    }
  }, [
    location.pathname,
    chatLoadingState,
    currentLocation,
    selectedHouseholdId,
    conversations,
    chat,
    dispatch
  ])

  useEffect(() => {
    if (!activeUserLoad.current) {
      activeUserLoad.current = true
      getAccount().then((data) => {
        const userLocalId = data?.localAccountId ?? ''
        const userTenantId = data?.tenantId ?? ''
        const userInfo: ActiveUserInfo = {
          id: `${userLocalId}.${userTenantId}`,
          email: data?.username ?? '',
          username: data?.name ?? data?.username ?? ''
        }
        dispatch(setActiveUserInfo(userInfo))
      })
    }
  })

  const { getRdot360Data } = useRdot360DataFetch()
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    if (!location.pathname.includes('client')) {
      return
    }
    const fetchData = async () => {
      setIsFetching(true)
      await getRdot360Data()
      setIsFetching(false)
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedHouseholdId])

  return (
    <div>
      {isFetching && <LoadingComponent isLoading={isFetching} />}
      <Panel
        headerText="RCopilot"
        isLightDismiss
        isOpen={showCopilot}
        onDismiss={() => setShowCopilot(false)}
        closeButtonAriaLabel="Close"
        type={PanelType.custom}
        customWidth={'50%'}
      >
        <div css={classes.container}>
          <ChatView />
        </div>
      </Panel>
    </div>
  )
}

export default Chat
