import { Conversations } from '../../redux/features/conversations/ConversationsState'
import { IChatMessage } from './ChatMessage'

export enum RouteLocation {
  BookDashboard,
  ClientDashboard,
  ServiceDashboard
}

export const locationMapping: { [key: string]: RouteLocation } = {
  '/advisory/dashboard': RouteLocation.BookDashboard,
  '/advisory/client': RouteLocation.ClientDashboard,
  '/advisory/alerts': RouteLocation.ServiceDashboard
}

export const mapLocation = (url: string): RouteLocation => {
  // Remove query parameters if any
  const cleanUrl = url.split('?')[0]

  // Split the URL by '/'
  const parts = cleanUrl.split('/')

  // Join the first three parts to get the base route
  const baseRoute = `/${parts[1]}/${parts[2]}`

  // Return the mapped location or a default value
  return locationMapping[baseRoute] ?? RouteLocation.BookDashboard
}

export const getNonHiddenChats = (
  conversations: Conversations,
  location: string,
  householdId: string
) => {
  const currentLocation = mapLocation(location)
  return Object.values(conversations).filter(
    (c) =>
      !c.hidden &&
      c.location === currentLocation &&
      c.householdId ===
        (currentLocation === RouteLocation.ClientDashboard ? householdId : '')
  )
}

export interface IChatSession {
  id: string
  title: string
  lastUpdatedTimestamp: number
  systemDescription: string
  memoryBalance: number
  location: RouteLocation
  householdId: string
  suggestedQuestions: string[]
  hidden: boolean
}

export interface ICreateChatSessionResponse {
  chatSession: IChatSession
  initialBotMessage: IChatMessage
}

export interface ILoadChatsResponse {
  chatSession: IChatSession
  botConversationHistory: IChatMessage[]
}
