import { SpeechService } from '../services/SpeechService'
import { useAuth } from './useAuth'

interface TokenResponse {
  token: string
  region: string
  isSuccess: boolean
}

export const useSpeech = () => {
  const { apiBaseUrl, getToken } = useAuth()

  const speechService = new SpeechService(apiBaseUrl)
  const getSpeechTokenAsync = async () => {
    try {
      const token = await getToken()
      return speechService.getSpeechTokenAsync(token)
    } catch (e: any) {
      console.error(e)
    }
  }

  const getSpeechRecognizerAsyncWithValidKey = async (
    response: TokenResponse
  ) => {
    try {
      return speechService.getSpeechRecognizerAsyncWithValidKey(response)
    } catch (e: any) {
      console.error(e)
    }
  }

  return {
    getSpeechTokenAsync,
    getSpeechRecognizerAsyncWithValidKey
  }
}
