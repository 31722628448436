import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { flow } from 'lodash/fp'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { AuthorRoles, IChatMessage } from '../../../libs/models/ChatMessage'
import { ChatState } from './ChatState'
import {
  ConversationInputChange,
  Conversations,
  ConversationsState,
  ConversationTitleChange,
  initialState
} from './ConversationsState'

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setConversations: (
      state: ConversationsState,
      action: PayloadAction<Conversations>
    ) => {
      state.conversations = action.payload
    },
    editConversationTitle: (
      state: ConversationsState,
      action: PayloadAction<ConversationTitleChange>
    ) => {
      const id = action.payload.id
      const newTitle = action.payload.newTitle
      state.conversations[id].title = newTitle
      frontLoadChat(state, id)
    },
    editConversationInput: (
      state: ConversationsState,
      action: PayloadAction<ConversationInputChange>
    ) => {
      const id = action.payload.id
      const newInput = action.payload.newInput
      state.conversations[id].input = newInput
    },
    setSelectedConversation: (
      state: ConversationsState,
      action: PayloadAction<string>
    ) => {
      state.selectedId = action.payload
    },
    addConversation: (
      state: ConversationsState,
      action: PayloadAction<ChatState>
    ) => {
      const newId = action.payload.id
      state.conversations = { [newId]: action.payload, ...state.conversations }
      state.selectedId = newId
    },
    /*
     * addMessageToConversationFromUser() and addMessageToConversationFromServer() both update the conversations state.
     * However they are for different purposes. The former action is for updating the conversation from the
     * webapp and will be captured by the SignalR middleware and the payload will be broadcasted to all clients
     * in the same group.
     * The addMessageToConversationFromServer() action is triggered by the SignalR middleware when a response is received
     * from the webapi.
     */
    addMessageToConversationFromUser: (
      state: ConversationsState,
      action: PayloadAction<{ message: IChatMessage; chatId: string }>
    ) => {
      const { message, chatId } = action.payload
      updateConversation(state, chatId, message)
    },
    addMessageToConversationFromServer: (
      state: ConversationsState,
      action: PayloadAction<{ message: IChatMessage; chatId: string }>
    ) => {
      const { message, chatId } = action.payload
      updateConversation(state, chatId, message)
    },
    /*
     * updateUserIsTyping() and updateUserIsTypingFromServer() both update a user's typing state.
     * However they are for different purposes. The former action is for updating an user's typing state from
     * the webapp and will be captured by the SignalR middleware and the payload will be broadcasted to all clients
     * in the same group.
     * The updateUserIsTypingFromServer() action is triggered by the SignalR middleware when a state is received
     * from the webapi.
     */
    updateUserIsTyping: (
      state: ConversationsState,
      action: PayloadAction<{
        userId: string
        chatId: string
        isTyping: boolean
      }>
    ) => {
      const { userId, chatId, isTyping } = action.payload
      updateUserTypingState(state, userId, chatId, isTyping)
    },
    updateUserIsTypingFromServer: (
      state: ConversationsState,
      action: PayloadAction<{
        userId: string
        chatId: string
        isTyping: boolean
      }>
    ) => {
      const { userId, chatId, isTyping } = action.payload
      updateUserTypingState(state, userId, chatId, isTyping)
    },
    updateBotResponseStatus: (
      state: ConversationsState,
      action: PayloadAction<{ chatId: string; status: string | undefined }>
    ) => {
      const { chatId, status } = action.payload
      const conversation = state.conversations[chatId]
      conversation.botResponseStatus = status
    },
    updateMessageProperty: <
      K extends keyof IChatMessage,
      V extends IChatMessage[K]
    >(
      state: ConversationsState,
      action: PayloadAction<{
        property: K
        value: V
        chatId: string
        messageIdOrIndex: string | number
        updatedContent?: string
        frontLoad?: boolean
      }>
    ) => {
      const {
        property,
        value,
        messageIdOrIndex,
        chatId,
        updatedContent,
        frontLoad
      } = action.payload
      const conversation = state.conversations[chatId]
      const conversationMessage =
        typeof messageIdOrIndex === 'number'
          ? conversation.messages[messageIdOrIndex]
          : conversation.messages.find((m) => m.id === messageIdOrIndex)

      if (conversationMessage) {
        if (
          typeof value === 'string' &&
          value.length > (conversationMessage[property] as string).length
        ) {
          conversationMessage[property] = value
        } else if (typeof value !== 'string') {
          conversationMessage[property] = value
        }
        if (updatedContent) {
          conversationMessage.content = updatedContent
        }
      }

      if (frontLoad) {
        frontLoadChat(state, chatId)
      }
    },
    deleteConversation: (
      state: ConversationsState,
      action: PayloadAction<string>
    ) => {
      const keys = Object.keys(state.conversations)
      const id = action.payload

      // If the conversation being deleted is the selected conversation, select the first remaining conversation
      if (id === state.selectedId) {
        if (keys.length > 1) {
          state.selectedId = id === keys[0] ? keys[1] : keys[0]
        } else {
          state.selectedId = ''
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [id]: _unused, ...rest } = state.conversations
      state.conversations = rest
    },
    updateFinalMessageFlag: (
      state: ConversationsState,
      action: PayloadAction<{ chatId: string; flag: boolean }>
    ) => {
      const { chatId, flag } = action.payload
      state.conversations[chatId].finalMessageFlag = flag
      return
    },
    updateFinalActionFlag: (
      state: ConversationsState,
      action: PayloadAction<{ chatId: string; flag: boolean }>
    ) => {
      const { chatId, flag } = action.payload
      state.conversations[chatId].finalActionFlag = flag
      return
    },
    disableConversation: (
      state: ConversationsState,
      action: PayloadAction<string>
    ) => {
      const id = action.payload
      state.conversations[id].disabled = true
      frontLoadChat(state, id)
      return
    },
    enableConversation: (
      state: ConversationsState,
      action: PayloadAction<string>
    ) => {
      const id = action.payload
      state.conversations[id].disabled = false
      frontLoadChat(state, id)
      return
    },
    updateSuggestedQuestions: (
      state: ConversationsState,
      action: PayloadAction<{
        chatId: string
        suggestedQuestions: string[]
      }>
    ) => {
      const id = action.payload.chatId
      state.conversations[id].suggestedQuestions =
        action.payload.suggestedQuestions
    },
    updateAdvancedReasoning: (
      state: ConversationsState,
      action: PayloadAction<{
        chatId: string
        advancedReasoning: boolean
      }>
    ) => {
      const id = action.payload.chatId
      state.conversations[id].advancedReasoning =
        action.payload.advancedReasoning
    }
  }
})

const frontLoadChat = (state: ConversationsState, id: string) => {
  const conversation = state.conversations[id]
  const { ...rest } = state.conversations
  state.conversations = { [id]: conversation, ...rest }
}

// made changes to updateConversation to handle the case where the bot sends a message with the same id as a previous message
const updateConversation = (
  state: ConversationsState,
  chatId: string,
  message: IChatMessage
) => {
  const existingMessage = state.conversations[chatId].messages.find(
    (msg) => msg.id === message.id
  )
  if (
    existingMessage?.id === message.id &&
    message.authorRole === AuthorRoles.Bot
  ) {
    return
  }
  state.conversations[chatId].messages.push({
    ...message
  })
  frontLoadChat(state, chatId)
}

const updateUserTypingState = (
  state: ConversationsState,
  userId: string,
  chatId: string,
  isTyping: boolean
) => {
  const conversation = state.conversations[chatId]
  const user = conversation.users.find((u) => u.id === userId)
  if (user) {
    user.isTyping = isTyping
  }
}

export const {
  setConversations,
  editConversationTitle,
  editConversationInput,
  setSelectedConversation,
  addConversation,
  addMessageToConversationFromUser,
  addMessageToConversationFromServer,
  updateMessageProperty,
  updateUserIsTyping,
  updateUserIsTypingFromServer,
  updateBotResponseStatus,
  deleteConversation,
  updateFinalMessageFlag,
  updateFinalActionFlag,
  disableConversation,
  enableConversation,
  updateSuggestedQuestions,
  updateAdvancedReasoning
} = conversationsSlice.actions

export default conversationsSlice.reducer

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.copilot.conversations

export const getSelectedChatID = flow(rootSelector, (x) => x.selectedId)
export const getConversations = flow(rootSelector, (x) => x.conversations)

export const useConversations = () => {
  const selectedId: string = useSelector(getSelectedChatID)
  const conversations: Conversations = useSelector(getConversations)

  return {
    selectedId,
    conversations
  }
}
