export enum AuthorRoles {
  User = 'User',
  Bot = 'Bot',
  // The participant who is not the current user nor the bot of the chat.
  Participant = 'Participant'
}

export enum ChatMessageType {
  Message = 'Message',
  Document = 'Document'
}

/**
 * States for RLHF
 */
export enum UserFeedback {
  Unknown,
  Requested,
  Positive,
  Negative
}

/**
 * Citation for the response
 */
export interface Citation {
  link: string
  sourceName: string
  snippet: string
  relevanceScore: number
}

export interface IChatMessage {
  chatId: string
  type: ChatMessageType
  timestamp: number
  userName: string
  userId: string
  content: string
  id?: string
  prompt?: string
  intent?: string
  citations?: Citation[]
  authorRole: AuthorRoles
  debug?: string
}
